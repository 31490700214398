import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    'to': 'To',
    'pickupdate': 'Pick-up Date',
    'Rates Auto Transport': 'Rates Auto Transport',
    'from': 'From',
    'city': 'City',
    'vehicle': {
      "select_type": "Select the type of vehicle you want to transport",
      "typeOfVehicle": "Select the type of ",
      "you_want": " you want to transport",
      "firstVehicle": "first vehicle",
      "secondVehicle": "second vehicle",
      "thirdVehicle": "third vehicle",
      "fourthVehicle": "fourth vehicle",
      select_brand: "Select a brand",
      select_year: "Select a year",
      select_model: "Select a model",
      my_car_not_here: "My car is not here",
    },
    'headerMessages': {
      'insured': '100% Insured',
      'discount': 'Get $50 off if you book now',
      'paymentOnDelivery': 'Make your payment when your vehicle is delivered',
      'bookNow': 'BOOK NOW',
      'trackShipping': 'Track your shipping',
      'bookWithReservePrice': 'Book your Shipping with $1 USD today'
    },
    'nav': {
      'home': 'Home',
      'aboutUs': 'About Us',
      'whyChooseUs': 'Why Choose Us',
      'coverageArea': 'Coverage Area',
      'faqs': 'FAQs',
      'contact': '1 870-667-5883'
    },
    'hero': {
      'title': 'We Make Your Vehicle Transportation Very Easy',
      'subtitle': 'A Reliable Booking System to Transport Your Vehicle',
    },
    'navigation': {
      'step1': '1. Route',
      'step2': '2. Vehicle',
      'step3': '3. Additional',
      'step4': '4. Finish',
    },
    'formContainer': {
      'infoText': 'Please enter your vehicle route information',
      'fromAddressLabel': 'From',
      'fromAddressPlaceholder': 'ZIP or City, State',
      'toAddressLabel': 'To',
      'toAddressPlaceholder': 'ZIP or City, State',
      'pickupDateLabel': 'Pick-up Date',
      'pickupDatePlaceholder': 'DD/MM/AAAA',
      'numberOfVehiclesLabel': 'How many vehicles',
    },
    'services': [
      {
        'title': 'Consultation and Advisory',
        'description': 'We offer confidence in our services to our customers by providing a detailed consultation with our Logistics team. Our logistics team will work with you step by step to explore the best transportation service that meets your individual needs.',
      },
      {
        'title': 'Reserve your spot with $1 USD',
        'description': 'Yes that’s right. Book your transportation today with $1.00 USD. As soon as we confirm the driver information (name and phone number) we’ll pay them a secure deposit between $200 and $300 USD to guarantee your space in the truck.',
      },
      {
        'title': 'Insurance Included',
        'description': 'We include insurance with every single car delivery service offered. All car transportation quotes are made via telephone consultation or on our online portal. Both avenues include full coverage of insurance until the automobile shipment is completed. Feel free to contact us at <strong>1 870-667-5883</strong> if you\'re ready to book your vehicle transportation with Rates Auto Transport today!',
      },
      {
        'title': 'Extended Hours',
        'description': 'Customers can reach us 24/7 to meet all your vehicle transportation needs. Your representative will be readily available to guide you through the entire process and answer any questions related to your vehicle transport. We believe in quality customer care and getting the job done right!',
      },
    ],
    'whyUsTitle': '3 Simple Reasons To Choose Us',
    "reason1": {
      "title": "Door to Door Service",
      "description": "Your vehicle will be delivered directly from the address of your choice straight to your door."
    },
    "reason2": {
      "title": "Full Coverage Insurance Included",
      "description": "All of our rates include insurance! We have your vehicle covered and will handle your vehicle with the utmost care throughout the entire transportation process so you can be stress-free."
    },
    "reason3": {
      "title": "Pay When Vehicle is Delivered",
      "description": "Our customers pay the delivery fee only when the transportation is complete! To secure a Rates Auto Transport vehicle service, provide a security deposit to reserve your transport service today!"
    },
    "destination": {
      "title": "Send your vehicle safely to the destination you desire",
      "subtitle": "We serve all the USA area. We not only provide the best transportation services to customers who are relocating or online car buyers but also extend to classic car enthusiasts and military families. We service clients from everywhere in the United States.",
      "mapSubtitle": "Ship your vehicle with us anywhere across the US!"
    },
    "testimonials_0_name": "Simon R",
    "testimonials_0_description": "This is the first time I transport my car, I was a little suspicious because I did not know anything about how it worked. But from the beginning, I was guided throughout the whole process and it was easier than I expected. Wonderful customer service.",
    "testimonials_1_name": "Glenda F",
    "testimonials_1_description": "Excellent service. Reliable company, my card wasn't charged without my consent. I was informed all the time about the whereabouts of my vehicle and received it on time without hassle.",
    "testimonials_2_name": "James M",
    "testimonials_2_description": "I've transported vehicles before but Rates Auto Transport has been the most seamless experience. From instant quotes that fit my budget to actually having a human being answer the phone each time I call, I am very happy with this service and will be using it for all my future transportation needs.",
    "testimonials_3_name": "Janice L",
    "testimonials_3_description": "I needed to move my car from my hometown to the college I was attending out of state. My dad found Rates Auto Transport and he was able to send my car easily. They picked it up at his house and I received it here without any problems.",
    faqsTitle: "Your Vehicle Transportation Questions Answered",
    faqs_q1_title: "How is your vehicle transport fee calculated?",
    faqs_q1_description: "We like to keep things efficient and straightforward. We ask our customers to provide basic details about their vehicles such as size, weight, the distance between pick-up and delivery, and the choice between open or enclosed car transport. By doing so, we can accurately evaluate the vehicle transport fees accordingly. Unlike other transport companies, we do not ask for any additional fees.",
    faqs_q2_title: "Can you ship your car with things in it?",
    faqs_q2_description: "Yes! Customers do not always have the time to take out their possessions from their vehicle before the start of service, so we often transport cars with small belongings inside. We understand and want to do our part to make our customers feel at ease. Our trusted and professional haulers will transport your vehicle without any loss. Be aware the insurance of these possessions in the vehicle is not in your binding contract. Any damage to belongings inside the car will not be Rates Auto Transport's responsibility. Please remove any belongings placed on the Driver's seat.",
    'faqs_accordion_q1_title': 'Do I have to be present all the time?',
    'faqs_accordion_q1_description': 'No, you do not have to be present during the entire duration of the pick-up or drop-off shipment process. However, we highly recommend having someone who is of legal age available while during the loading or unloading of the vehicle.',

    'faqs_accordion_q2_title': 'What if I forgot my belongings in there?',
    'faqs_accordion_q2_description': "What if I forgot My Belongings In The Vehicle? As mentioned, we allow for small belongings to be inside the car. However, there is a weight limit of 100 lbs. If surpassed, this will result in an extra charge. If you have any questions regarding what can be inside your vehicle on pick-up day, we recommend contacting your logistics agent. Be aware the insurance of these possessions in the vehicle is not in your binding contract. Any damage to belongings inside the car will not be Rates Auto Transport's responsibility.",

    'faqs_accordion_q3_title': 'What if I want to pay by credit card?',
    'faqs_accordion_q3_description': 'We accept various forms of payment ranging from credit cards to PayPal. Customers can pay cash on delivery (COD), but a deposit payment is required to secure your transport service.',
    'faqs_accordion_q4_title': 'Is there an upfront payment?',
    'faqs_accordion_q4_description': "We only take a $1.00 payment to make your reservation.",

    'faqs_accordion_q5_title': 'What If I want an immediate delivery?',
    'faqs_accordion_q5_description': 'Rates Auto Transport is available 24/7/365, enabling us to support our customers year-round when they need us! Customers can call us anytime and book a vehicle transport service. We assure you we will work to deliver your vehicle as safely and quickly to your desired location as possible.',

    'faqs_accordion_q6_title': 'Can you transport for auto shows?',
    'faqs_accordion_q6_description': 'Yes, we are available for auto shows carrier services! It brings us pride to take part in helping your auto show as your chosen carrier. We wish you great luck ahead!',

    'faqs_ready': 'Ready to get started?',
    'faqs_ready_link': 'Go to our calculator',
    'faqs_subBox_q1_title': 'How do I choose an auto transport company?',
    'faqs_subBox_q1_description': "Deciding which company best suits your car transportation needs can be stressful and exhausting. We recommend researching any company before handing over your vehicle to them. Indicators to look at the level of hauler experience, the company's market reputation, and the quality of consultation services companies offer.<br><br>You can also read up on and explore company reviews. Reviews can reveal a lot about a company's customer experience plus help you make the right decision. We encourage you to read our reviews and reach out if you have any questions! Our customer reviews reflect our years of service and dedication to our clients.<br><br>We at Rates Auto Transport are the most experienced and reputable transport company in the United States. We have customers ranging from every state! You can trust us to get the job done right!",

    'faqs_accordion_q7_title': 'How long does the delivery of a vehicle take?',
    'faqs_accordion_q7_description': "The delivery time will depend on the number of miles traveled and some conditions such as weather and traffic.<br><ul><li>Routes between 500 and 1,000 miles can take from 2 to 3 days.</li><li>Routes between 1,000 and 2,000 miles can take from 4 to 7 days.</li><li>Routes of more than 2,000 miles can take between 7 and 12 days.</li></ul>",
    'faqs_accordion_q8_title': 'What is covered under insurance?',
    'faqs_accordion_q8_description': "We ONLY cover vehicle insurance in our quote. Your possessions in the car are not part of the insurance quote. Be aware the insurance of these possessions in the vehicle is not in your binding contract. Any damage to belongings inside the car will not be Rates Auto Transport's responsibility.",

    'faqs_accordion_q9_title': 'What is Door-to-Door auto transport?',
    'faqs_accordion_q9_description': 'This offered service ensures pick-up and drop-off as closely and safely to your home as possible. However, it is important to note many cities restrict large trucks from driving into various residential areas.',

    'faqs_accordion_q10_title': 'What if my car is heavy? Does that result in a higher-priced delivery quote?',
    'faqs_accordion_q10_description': 'We calculate our rates according to the size and weight of the vehicle with no additional charges to the customer.',

    'faqs_accordion_q11_title': 'What do I do if my car is damaged during transport?',
    'faqs_accordion_q11_description': 'First things first, DO NOT RECEIVE the bill of lading when your vehicle is delivered to you if your car is not in the exact same condition that you shipped it in, please call Rates auto transport customer service immediately. Your car may arrive a little dirty on the outside.',

    'faqs_accordion_q12_title': 'Should I wash my car before?',
    'faqs_accordion_q12_description': "That is a customer's personal choice as it is not a requirement for our vehicle transport services. Your vehicle's cleanliness does not affect your vehicle transport quote.",

    'faqs_accordion_q13_title': 'What if I forgot to mention any detail of my car shipping?',
    'faqs_accordion_q13_description': "Don't worry! You can email us at any time at XXXX and call our customer service number at xxxxx. Our customer care agents will add your desired time slot. No worries!",
    'preFooter': 'We at Rates Auto Transport are dedicated professionals who provide streamlined vehicle shipping services to our clients. We are proud our customer reviews showcase our guaranteed quality and attention to detail to our customers. <br> <br> We are licensed professionals who strive to provide vehicle transportation services at minimum costs to our clients. We do not believe in overcharging, hidden fees, or making short-term clients. We believe in delivering the best-quality solutions to retain long-term clients who are satisfied with our vehicle transport services.<br><br>We have been in this industry for over 7 years and confidently possess the expertise and affordable rates to make us surpass all other competitors. Our loyal customer base is increasing by-the-day, which is an example of the level of service we strive to provide our clients. Our mission has always been to offer uninterrupted services 24/7/365 days of the year. Our dedication to customer satisfaction is unlike any other auto transport company in the United States. We hope to service your vehicle transport needs today!',
    'preFoot_title': 'Leave it to the Pros',
    'runAndDrive': 'Does Your Vehicle Run And Drive?',
    'personalObjects': 'Will Your Vehicle Have Personal Items?',
    'truck_Type': "Truck type",
    'open_truck': 'Open (Recomended)',
    'enclosed_truck': 'Enclosed (+$550)',
    'transported_vehicule': 'The vehicle to be transported is',
    'transported_vehicules': 'The vehicles to be transported are',
    'route_information': 'Route information',
    'complete_steps': 'Complete the final steps',
    'transport': 'Transport',
    'last_step': 'Last step!',
    'credit_charge': 'We will be in touch with you',
    'back': 'Back',
    'next': 'Next',
    "check_3_quotes": 'Check It Out, Get 3 Different Quotes Right Now!',
    'select_aditional_services': 'Select Your Additional Service',
    'select_aditional_services_for': 'Select Your Additional Service for the',
    "description_rates": "We at Rates Auto Transport provide cost-efficient vehicle transportation and vehicle shipment services. We are the most sophisticated auto transport company dedicated to providing the ultimate customer service and convenience. Give us the opportunity to transport your car, we promise the best service or your money will be returned back.",
    'how_many_veh': 'How many vehicles',
    "selectVehicle": {
      "single": "Select the vehicle",
      "first": "Select the first vehicle",
      "second": "Select the second vehicle",
      "third": "Select the third vehicle",
      "fourth": "Select the fourth vehicle"
    },
    'aditional_services': 'Your additional services are',
    'aditional_first_veh': 'Your additional services for the first vehicle are',
    'aditional_second_veh': 'Your additional services for the second vehicle are',
    'aditional_third_veh': 'Your additional services for the third vehicle are',
    'aditional_fourth_veh': 'Your additional services for the fourth vehicle are',
    'first_name': 'First name',
    'last_name': 'Last name',
    'phone_number': 'Phone Number',
    'more_details': 'A couple more specifics, we want to get everything right!',
    'discount_price': 'Discount Price',
    'due': 'Due Now 1$',
    "mainContainer": {
      'mainTitle': 'Get an INSTANT quote',
      "urgent": "Urgent! SOS",
      "oneToThreeDaysPickUp": "1 - 3 Days PICK UP!",
      "bookNowButton": "BOOK NOW",
      "withReservePrice": "WITH $1 USD",
      "doorToDoor": "Door To Door Service.",
      "makePayment": "Make Payment When Vehicle Is Recive.",
      "insured": "100% INSURED.",
      "discount": "$50USD Discount",
      "standard": "Standard",
      "threeToFiveDaysPickUp": "3 - 5 Days PICK UP!",
      "bookNowButtonStandard": "BOOK NOW",
      "extended": "Extended",
      "fiveToTenDaysPickUp": "5 - 10 Days PICK UP!",
      "bookNowButtonExtended": "BOOK NOW",
      "getInstantQuote": "Get an INSTANT quote",
      "stepOne": "1. Route",
      "stepTwo": "2. Vehicle",
      "stepThree": "3. Additional",
      "stepFour": "4. Finish",
      "bestPriceAndService": "We not only offer you the best price, we offer you the best service!",
      "sendQuoteToEmail": "Receive this quote in the mail.",
      "enterEmailPlaceholder": "you.example.com",
      "sendQuoteButton": "Send",
      "sending": "Sending...",
      "emailSentSuccessfully": "Your quote has been successfully sent to your email",
      "emailError": "Please verify that you've entered the correct email address",
      "bookWithAgent": "Book with one of our friendly Customer Service Agents!",
      "phone": "1 870-667-5883",

    },
    "successful": {
      "transport": "Transport",
      "pickup": "Pickup",
      "delivery": "Delivery",
      "bookNow": "Book Now",
      "finish": "Finish",
      "paymentMethodSaved": "Success! Your request has been sent.",
      "beAware": "Please from here on out be aware of your phone and unknown numbers, we will call and text you at any time from now with updates of your vehicle shipping."
    },
    "pickup": "Pickup",
    "delivery": "Delivery",
    "bookNow": "Book Now",
    "finish": "Finish",
    completeTheFinalSteps: 'Complete the final steps',
    'state': "State",
    'vehicleDeliveryDetails': 'Vehicle delivery details',
    "vehiclePickupDetails": "Vehicle Pickup Details",
    "customAdditional": {
      'special_quote': "Due to the specialized nature of your order, we require additional details to provide you with the most accurate service. A member of our team will reach out to you via phone to discuss your needs further."
    },
    "messages": {
      "thankYou": "Thank you for your interest in our services!",
      "submitted": "Your request has been successfully submitted.",
      "agentWillContact": "One of our dedicated agents will be in touch with you shortly to discuss the details and provide you with the most accurate service tailored to your needs.",
      "appreciation": "We appreciate your business and look forward to serving you soon!"
    },
    finish: 'Finish',
    promo: {
      header: 'Special Promo!',
      body: 'Book now and get a $50 discount on your next order!',
      contact: 'If you have any questions, feel free to contact us at:',
      phoneNumber: 'Call: 1 870-667-6043'
    },
    assets: {
      route_video_home: require('@/assets/video/home_en.mp4')
    }
  },
  /**
   *
   * Español
   *
   */
  es: {
    promo: {
      header: '¡Promoción Especial!',
      body: '¡Reserva ahora y obtén un descuento de $50 en tu próximo pedido!',
      contact: 'Si tienes alguna pregunta, no dudes en contactarnos al:',
      phoneNumber: 'Llamar: 1 870-667-6043'
    },
    'from': 'Desde',
    'city': 'Ciudad',
    'state': 'Estado',
    finish: 'Finalizar',
    'how_many_veh': 'Cuantos vehiculos',
    'pickupdate': 'Fecha de recogida',
    'vehicleDeliveryDetails': 'Detalles de entrega del vehículo',
    'to': 'Hasta',
    'Rates Auto Transport': 'Tarifas de transporte automático',
    'vehicle': {
      "select_type": "Seleccione el tipo de vehículo que desea transportar",
      "typeOfVehicle": "Seleccione el tipo de ",
      "you_want": " que desea transportar",
      "firstVehicle": "primer vehículo",
      "secondVehicle": "segundo vehículo",
      "thirdVehicle": "tercer vehículo",
      "fourthVehicle": "cuarto vehículo",
      select_brand: "Seleccione una marca",
      select_year: "Seleccione un año",
      select_model: "Seleccione un modelo",
      my_car_not_here: "Mi auto no está aquí",
    },
    'headerMessages': {
      'insured': '100% Asegurado',
      'discount': 'Obtenga $50 de descuento si reserva ahora',
      'paymentOnDelivery': 'Haga su pago cuando su vehículo sea entregado',
      'bookNow': 'RESERVA AHORA',
      'trackShipping': 'Rastrea tu envío',
      'bookWithReservePrice': 'Reserva tu envío con $1 USD hoy'
    },
    'nav': {
      'home': 'Inicio',
      'aboutUs': 'Sobre Nosotros',
      'whyChooseUs': 'Por Qué Elegirnos',
      'coverageArea': 'Cobertura',
      'faqs': 'FAQS',
      'contact': '1 870-667-6043'
    },
    'hero': {
      'title': 'Hacemos que el transporte de su vehículo sea más fácil',
      'subtitle': 'Un sistema de reserva confiable para transportar su vehículo',
    },

    'navigation': {
      'step1': '1. Ruta',
      'step2': '2. Vehículo',
      'step3': '3. Adicional',
      'step4': '4. Finalizar',
    },
    'formContainer': {
      'infoText': 'Por favor, ingrese la información de la ruta de su vehículo',
      'fromAddressLabel': 'Desde',
      'fromAddressPlaceholder': 'Código postal o ciudad, estado',
      'toAddressLabel': 'Hasta',
      'toAddressPlaceholder': 'Código postal o ciudad, estado',
      'pickupDateLabel': 'Fecha de recogida',
      'pickupDatePlaceholder': 'DD/MM/AAAA',
      'numberOfVehiclesLabel': 'Cuántos vehículos',
    },
    'services': [
      {
        'title': 'Consultoría y asesoramiento',
        'description': 'Ofrecemos confianza en nuestros servicios a nuestros clientes al proporcionar una consulta detallada con nuestro equipo de Logística. Nuestro equipo de logística trabajará contigo paso a paso para explorar el mejor servicio de transporte que se adapte a tus necesidades individuales.',
        icon_color: 'red',
        line_color: 'red'
      },
      {
        'title': 'Reserva tu lugar con $1 USD',
        'description': 'Reserva tu transporte hoy mismo con $1.00 USD. Tan pronto como confirmemos la información del conductor (nombre y número de teléfono), les pagaremos un depósito seguro entre $200 y $300 USD para garantizar tu espacio en el camión.',
        icon_color: 'yellow',
        line_color: 'yellow'
      },
      {
        'title': 'Seguro incluido',
        'description': 'Incluimos un seguro con cada servicio de entrega de automóviles que ofrecemos. Todas las cotizaciones de transporte de automóviles se realizan a través de consulta telefónica o en nuestro portal en línea. Ambos medios incluyen cobertura completa de seguro hasta que se complete el envío del automóvil. ¡No dudes en contactarnos al <strong>1 870-667-6043</strong> si estás listo para reservar el transporte de tu vehículo con Rates Auto Transport hoy mismo!',
        icon_color: 'green',
        line_color: 'green'
      },
      {
        'title': 'Horario extendido',
        'description': 'Los clientes pueden contactarnos las 24 horas del día, los 7 días de la semana, para satisfacer todas sus necesidades de transporte de vehículos. Nuestro representante estará disponible de inmediato para guiarte a lo largo de todo el proceso y responder a cualquier pregunta relacionada con el transporte de tu vehículo. Creemos en la atención al cliente de calidad y en hacer el trabajo de manera correcta.',
        icon_color: 'red',
        line_color: 'red'
      },
    ],
    'whyUsTitle': '3 Razones Simples Para Elegirnos',
    'reason1': {
      'title': 'Servicio Puerta a Puerta',
      'description': 'Tu vehículo será entregado directamente desde la dirección que elijas hasta tu puerta.'
    },
    'reason2': {
      'title': 'Seguro de Cobertura Completa Incluido',
      'description': '¡Todas nuestras tarifas incluyen seguro! Tenemos tu vehículo cubierto y lo manejaremos con el máximo cuidado durante todo el proceso de transporte para que puedas estar libre de estrés.'
    },
    'reason3': {
      'title': 'Paga Cuando se Entrega el Vehículo',
      'description': '¡Nuestros clientes pagan la tarifa de entrega solo cuando se completa el transporte! Para asegurar un servicio de vehículo de Rates Auto Transport, proporciona un depósito de seguridad para reservar tu servicio de transporte hoy.'
    },
    "destination": {
      "title": "Envíe su vehículo de manera segura al destino que desee",
      "subtitle": "Servimos a toda el área de EE. UU. No solo brindamos los mejores servicios de transporte a los clientes que se están mudando o comprando automóviles en línea, sino que también nos extendemos a los entusiastas de los automóviles clásicos y las familias militares. Atendemos a clientes de todas partes de Estados Unidos.",
      "mapSubtitle": "¡Envíe su vehículo con nosotros a cualquier parte de EE. UU.!"
    },
    "testimonials_0_name": "Simon R",
    "testimonials_0_description": "Esta es la primera vez que transporto mi coche, estaba un poco sospechoso porque no sabía nada sobre cómo funcionaba. Pero desde el principio me guiaron durante todo el proceso y fue más fácil de lo que esperaba. Maravilloso servicio al cliente.",
    "testimonials_1_name": "Glenda F",
    "testimonials_1_description": "Excelente servicio. Empresa confiable, no cargaron mi tarjeta sin mi consentimiento. Me informaron todo el tiempo sobre el paradero de mi vehículo y lo recibí a tiempo sin problemas.",
    "testimonials_2_name": "James M",
    "testimonials_2_description": "He transportado vehículos antes, pero Rates Auto Transport ha sido la experiencia más fluida. Desde cotizaciones instantáneas que se ajustan a mi presupuesto hasta tener una persona real que responde el teléfono cada vez que llamo, estoy muy contento con este servicio y lo usaré para todas mis futuras necesidades de transporte.",
    "testimonials_3_name": "Janice L",
    "testimonials_3_description": "Necesitaba mover mi coche desde mi ciudad natal hasta la universidad a la que asistía fuera del estado. Mi padre encontró Rates Auto Transport y pudo enviar mi coche fácilmente. Lo recogieron en su casa y lo recibí aquí sin ningún problema.",
    faqsTitle: "Respuestas a Sus Preguntas Sobre el Transporte de Vehículos",
    faqs_q1_title: "¿Cómo se calcula la tarifa de transporte de su vehículo?",
    faqs_q1_description: "Nos gusta mantener las cosas eficientes y directas. Pedimos a nuestros clientes que proporcionen detalles básicos sobre sus vehículos, como el tamaño, el peso, la distancia entre la recogida y la entrega, y la elección entre el transporte de vehículos abierto o cerrado. Al hacerlo, podemos evaluar con precisión las tarifas de transporte del vehículo en consecuencia. A diferencia de otras compañías de transporte, no solicitamos tarifas adicionales.",
    faqs_q2_title: "¿Puede enviar su coche con cosas dentro?",
    faqs_q2_description: "¡Sí! Los clientes no siempre tienen tiempo para sacar sus pertenencias de su vehículo antes del inicio del servicio, por lo que a menudo transportamos coches con pequeñas pertenencias dentro. Entendemos y queremos hacer nuestra parte para que nuestros clientes se sientan cómodos. Nuestros transportistas de confianza y profesionales transportarán su vehículo sin ninguna pérdida. Tenga en cuenta que el seguro de estas posesiones en el vehículo no está en su contrato vinculante. Cualquier daño a las pertenencias dentro del coche no será responsabilidad de Rates Auto Transport. Por favor, retire cualquier pertenencia colocada en el asiento del conductor.",
    'faqs_accordion_q1_title': '¿Debo estar presente todo el tiempo?',
    'faqs_accordion_q1_description': 'No, no tienes que estar presente durante toda la duración del proceso de recogida o entrega del envío. Sin embargo, recomendamos encarecidamente tener a alguien que sea mayor de edad disponible durante la carga o descarga del vehículo.',
    'faqs_accordion_q2_title': '¿Qué pasa si olvidé mis pertenencias?',
    'faqs_accordion_q2_description': "¿Qué pasa si olvidé mis pertenencias en el vehículo? Como se mencionó, permitimos que haya pequeñas pertenencias dentro del automóvil. Sin embargo, hay un límite de peso de 100 lbs. Si se supera, esto resultará en un cargo adicional. Si tiene alguna pregunta sobre qué puede haber dentro de su vehículo el día de la recogida, le recomendamos que se comunique con su agente de logística. Tenga en cuenta que el seguro de estas posesiones en el vehículo no está en su contrato vinculante. Cualquier daño a las pertenencias dentro del automóvil no será responsabilidad de Rates Auto Transport.",
    'faqs_accordion_q3_title': '¿Qué pasa si quiero pagar con tarjeta de crédito?',
    'faqs_accordion_q3_description': 'Aceptamos diversas formas de pago que van desde tarjetas de crédito hasta PayPal. Los clientes pueden pagar en efectivo contra entrega (COD), pero se requiere un pago de depósito para asegurar su servicio de transporte.',
    'faqs_accordion_q4_title': '¿Hay un pago inicial?',
    'faqs_accordion_q4_description': "Únicamente tomamos un pago de $1.00 para realizar tu reserva.",

    'faqs_accordion_q5_title': '¿Qué pasa si quiero una entrega inmediata?',
    'faqs_accordion_q5_description': 'Rates Auto Transport está disponible las 24 horas del día, los 7 días de la semana, lo que nos permite apoyar a nuestros clientes durante todo el año cuando nos necesitan. Los clientes pueden llamarnos en cualquier momento y reservar un servicio de transporte de vehículos. Le aseguramos que trabajaremos para entregar su vehículo de la manera más segura y rápida posible a la ubicación que desee.',

    'faqs_accordion_q6_title': '¿Pueden transportar para ferias de autos?',
    'faqs_accordion_q6_description': 'Sí, ¡estamos disponibles para servicios de transportistas de ferias de autos! Nos enorgullece participar en ayudar a su feria de autos como su transportista elegido. ¡Le deseamos mucha suerte!',

    'faqs_ready': '¿Listo para comenzar?',
    'faqs_ready_link': 'Vaya a nuestro calculador',
    'faqs_subBox_q1_title': '¿Cómo elijo una empresa de transporte de automóviles?',
    'faqs_subBox_q1_description': "Decidir qué empresa se adapta mejor a sus necesidades de transporte de automóviles puede ser estresante y agotador. Recomendamos investigar cualquier empresa antes de entregarles su vehículo. Los indicadores a tener en cuenta son el nivel de experiencia del transportista, la reputación de la empresa en el mercado y la calidad de los servicios de consulta que ofrecen las empresas.<br><br>También puede leer y explorar opiniones de la empresa. Las reseñas pueden revelar mucho sobre la experiencia del cliente de una empresa y ayudarlo a tomar la decisión correcta. ¡Le animamos a leer nuestras reseñas y comunicarse si tiene alguna pregunta! Nuestras opiniones de los clientes reflejan nuestros años de servicio y dedicación a nuestros clientes.<br><br>En Rates Auto Transport somos la empresa de transporte más experimentada y de mejor reputación en los Estados Unidos. ¡Tenemos clientes de todos los estados! ¡Puede confiar en nosotros para hacer el trabajo bien hecho!",

    'faqs_accordion_q7_title': '¿Cuánto tiempo tarda la entrega de un vehículo?',
    'faqs_accordion_q7_description': "El tiempo de entrega dependerá de la cantidad de millas recorridas y de algunas condiciones como el clima y el tráfico.<br><ul><li>Rutas entre 500 y 1,000 millas pueden tardar de 2 a 3 días.</li><li>Rutas entre 1,000 y 2,000 millas pueden tardar de 4 a 7 días.</li><li>Rutas de más de 2,000 millas pueden tardar entre 7 y 12 días.</li></ul>",
    'faqs_accordion_q8_title': '¿Qué está cubierto por el seguro?',
    'faqs_accordion_q8_description': 'SOLO cubrimos el seguro del vehículo en nuestra cotización. Sus pertenencias en el auto no forman parte de la cotización del seguro. Tenga en cuenta que el seguro de estas pertenencias en el vehículo no está en su contrato vinculante. Cualquier daño a las pertenencias dentro del automóvil no será responsabilidad de Rates Auto Transport.',

    'faqs_accordion_q9_title': '¿Qué es el transporte de puerta a puerta?',
    'faqs_accordion_q9_description': 'Este servicio ofrecido asegura la recogida y entrega lo más cerca y seguro posible de su hogar. Sin embargo, es importante tener en cuenta que muchas ciudades restringen la circulación de camiones grandes en diversas áreas residenciales.',

    'faqs_accordion_q10_title': '¿Qué pasa si mi coche es pesado? ¿Eso resulta en un presupuesto de entrega más alto?',
    'faqs_accordion_q10_description': 'Calculamos nuestras tarifas de acuerdo con el tamaño y el peso del vehículo sin cargos adicionales al cliente.',

    'faqs_accordion_q11_title': '¿Qué hago si mi coche se daña durante el transporte?',
    'faqs_accordion_q11_description': 'Lo primero es lo primero, NO RECIBA el conocimiento de embarque cuando le entreguen su vehículo. Si su coche no está en las mismas condiciones exactas en las que lo envió, llame inmediatamente al servicio de atención al cliente de Rates Auto Transport. Su automóvil puede llegar un poco sucio por fuera.',

    'faqs_accordion_q12_title': '¿Debo lavar mi coche antes?',
    'faqs_accordion_q12_description': 'Esa es una elección personal del cliente, ya que no es un requisito para nuestros servicios de transporte de vehículos. La limpieza de su vehículo no afecta su presupuesto de transporte de vehículos.',
    select_aditional_services: 'Seleccione su servicio adicional',
    select_aditional_services_for: 'Seleccione su servicio adicional para el', // or 'para la'
    'faqs_accordion_q13_title': '¿Qué pasa si olvidé mencionar algún detalle de mi envío de coche?',
    'faqs_accordion_q13_description': '¡No te preocupes! Puede enviarnos un correo electrónico en cualquier momento a XXXX y llamar a nuestro número de servicio al cliente en xxxxx. Nuestros agentes de atención al cliente agregarán su franja horaria deseada. ¡Sin preocupaciones!',
    'preFooter': 'En Rates Auto Transport, somos profesionales dedicados que ofrecen servicios de transporte de vehículos eficientes a nuestros clientes. Estamos orgullosos de que nuestras opiniones de clientes muestren nuestra calidad garantizada y atención al detalle hacia nuestros clientes. <br> <br> Somos profesionales con licencia que se esfuerzan por proporcionar servicios de transporte de vehículos a costos mínimos para nuestros clientes. No creemos en cobrar de más, tarifas ocultas ni en tener clientes a corto plazo. Creemos en ofrecer soluciones de la mejor calidad para retener a clientes a largo plazo que estén satisfechos con nuestros servicios de transporte de vehículos.<br><br>Llevamos más de 7 años en esta industria y contamos con la experiencia y tarifas asequibles para superar a todos los demás competidores. Nuestra base de clientes leales está aumentando día a día, lo que es un ejemplo del nivel de servicio que nos esforzamos por ofrecer a nuestros clientes. Nuestra misión siempre ha sido ofrecer servicios ininterrumpidos los 365 días del año, las 24 horas del día. Nuestra dedicación a la satisfacción del cliente es incomparable con cualquier otra empresa de transporte de automóviles en los Estados Unidos. ¡Esperamos atender sus necesidades de transporte de vehículos hoy mismo!',
    'preFoot_title': "Deja que los profesionales se encarguen",
    'runAndDrive': "¿Su vehículo funciona?",
    'personalObjects': "¿Su vehículo llevará objetos personales?",
    'truck_Type': "Tipo de camión",
    'open_truck': 'Abierto (Recomendado)',
    'enclosed_truck': 'Cerrado (+550$)',
    'transported_vehicule': 'El vehiculo a transportar es',
    'transported_vehicules': 'Los vehiculos a transportar son',
    'route_information': 'Información de ruta',
    'complete_steps': "Complete los pasos finales",
    'transport': 'Transporte',
    'last_step': 'Ultimo paso!',
    'credit_charge': "Nos estaremos comunicando con usted",
    'back': 'Atras',
    'next': 'Continuar',
    'check_3_quotes': '¡Échale un vistazo, obtén 3 cotizaciones diferentes ahora mismo!',
    "description_rates": "Nosotros en Rates Auto Transport proporcionamos servicios de transporte y envío de vehículos rentables. Somos la compañía de transporte automovilístico más sofisticada, dedicada a ofrecer el mejor servicio al cliente y comodidad. Déjanos la oportunidad de transportar tu coche, prometemos el mejor servicio o te devolveremos tu dinero.",
    "selectVehicle": {
      "single": "Selecciona el vehículo",
      "first": "Selecciona el primer vehículo",
      "second": "Selecciona el segundo vehículo",
      "third": "Selecciona el tercer vehículo",
      "fourth": "Selecciona el cuarto vehículo"
    },
    'aditional_services': 'Tus servicios adicionales son',
    'aditional_first_veh': 'Los servicios adicionales para el primer vehículo son',
    'aditional_second_veh': 'Los servicios adicionales para el segundo vehículo son',
    'aditional_third_veh': 'Los servicios adicionales para el tercer vehículo son',
    'aditional_fourth_veh': 'Los servicios adicionales para el cuarto vehículo son',
    'first_name': 'Nombre',
    'last_name': 'Apellido',
    'phone_number': 'Numero de Telefono',
    'more_details': 'Un par de detalles más, ¡queremos hacer todo correctamente!',
    'discount_price': 'Precio con descuento',
    'due': 'A pagar ahora: $1',
    "mainContainer": {
      'mainTitle': 'Obtenga una cotización INSTANTÁNEA',
      "urgent": "¡Urgente! SOS",
      "oneToThreeDaysPickUp": "Recogida en 1 - 3 días!",
      "bookNowButton": "RESERVAR AHORA",
      "withReservePrice": "CON $1 USD",
      "doorToDoor": "Servicio puerta a puerta.",
      "makePayment": "Realice el pago cuando se reciba el vehículo.",
      "insured": "100% ASEGURADO.",
      "discount": "Descuento de $50USD",
      "standard": "Estándar",
      "threeToFiveDaysPickUp": "Recogida en 3 - 5 días!",
      "bookNowButtonStandard": "RESERVAR AHORA",
      "extended": "Extendido",
      "fiveToTenDaysPickUp": "Recogida en 5 - 10 días!",
      "bookNowButtonExtended": "RESERVAR AHORA",
      "getInstantQuote": "Obtenga una cotización INSTANTÁNEA",
      "stepOne": "1. Ruta",
      "stepTwo": "2. Vehículo",
      "stepThree": "3. Adicional",
      "stepFour": "4. Finalizar",
      "bestPriceAndService": "¡No solo le ofrecemos el mejor precio, le ofrecemos el mejor servicio!",
      "sendQuoteToEmail": "Recibe está cotización en el correo",
      "enterEmailPlaceholder": "you.example.com",
      "sendQuoteButton": "Enviar",
      "sending": "Enviando...",
      "emailSentSuccessfully": "Su cotización ha sido enviada exitosamente a su correo electrónico",
      "emailError": "Verifique que haya ingresado la dirección de correo electrónico correcta",
      "bookWithAgent": "¡Reserve con uno de nuestros amables agentes de servicio al cliente!",
      "phone": "1 870-667-6043"
    },
    "successful": {
      "transport": "Transporte",
      "pickup": "Recogida",
      "delivery": "Entrega",
      "bookNow": "Reservar Ahora",
      "finish": "Finalizar",
      "paymentMethodSaved": "¡Éxito! su petición ha sido enviada.",
      "beAware": "Por favor, a partir de ahora esté atento a su teléfono y números desconocidos, le llamaremos y enviaremos mensajes de texto en cualquier momento a partir de ahora con actualizaciones sobre el envío de su vehículo."
    },
    "pickup": "Recogida",
    "delivery": "Entrega",
    "bookNow": "Reservar",
    "finish": "Finalizar",
    completeTheFinalSteps: 'Completa los pasos finales',
    "vehiclePickupDetails": "Detalles de la recogida del vehículo",
    "name": "Nombre",
    "lastName": "Apellido",
    "phone": "Telefono",
    "customAdditional": {
      'special_quote': "Debido a la naturaleza especializada de su pedido, requerimos detalles adicionales para brindarle el servicio más preciso. Un miembro de nuestro equipo se comunicará con usted por teléfono para discutir más a fondo sus necesidades."
    },
    "messages": {
      "thankYou": "¡Gracias por su interés en nuestros servicios!",
      "submitted": "Su solicitud ha sido enviada con éxito.",
      "agentWillContact": "Uno de nuestros agentes especializados se pondrá en contacto con usted en breve para discutir los detalles y ofrecerle el servicio más adecuado a sus necesidades.",
      "appreciation": "¡Agradecemos su preferencia y esperamos servirle pronto!"
    },
    assets: {
      route_video_home: require('@/assets/video/home_es.mp4')
    }
  },
  // ... other languages
};

// Detect browser language
const supportedLanguages = ['en', 'es'];
const browserLanguage = navigator.language.split('-')[0];

export const i18n = createI18n({
  locale: supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en',
  fallbackLocale: 'en',
  messages
});