<template>
  <div>
    <Header/>
    <router-view/>
    <PreFooter/>
    <Footer/>
    
    <PopupCard :show="showPopup" @close="showPopup = false">
      <template v-slot:header>
        <h3>{{ $t('promo.header') }}</h3>
      </template>
      <template v-slot:body>
        <p>{{ $t('promo.body') }}</p>
        <p>{{ $t('promo.contact') }}</p>
        <p>{{ $t('promo.phoneNumber') }}</p>
      </template>
    </PopupCard>
    <SmallModal :currentMessage="currentMessage" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import PreFooter from '@/components/PreFooter.vue'
import Footer from '@/components/Footer.vue'
import PopupCard from '@/components/PopupCard.vue';
import SmallModal from '@/components/SmallModal.vue';

export default {
  name: 'App',
  components: {
    Header,
    PreFooter,
    Footer,
    PopupCard,
    SmallModal
  },
  data() {
    return {
      showPopup: false,
      transportMessages: [
        "🚗 Ana just booked from Texas to Florida",
        "🚛 John just made a purchase for a truck transport",
        "🚢 Carlos just paid to send his vehicle to Puerto Rico",
        "🚗 Emily booked a car transport from New York to California",
        "🚚 Michael just booked a moving truck from Oregon to Nevada",
        "🏍️ Lisa just shipped her motorcycle from Florida to Texas",
        "🚗 Mark booked a transport from Illinois to Georgia",
        "🚛 Karen just secured a freight service from Ohio to New Jersey",
        "🚗 Alex booked a transport from Colorado to Arizona",
        "🚚 Laura just scheduled a delivery from Washington to Utah",
        "🚙 Steve just reserved a SUV transport from Alabama to Louisiana",
        "🚐 Nina booked a van transport from Michigan to Wisconsin",
        "🚗 Dan just booked a classic car shipment from Tennessee to Virginia",
        "🚛 Rachel just set up a freight service from Pennsylvania to North Carolina",
        "🚗 Chris just booked a transport from Indiana to Kentucky",
        "🚚 Samantha just organized a move from Missouri to Arkansas",
        "🚙 Tony just scheduled a SUV transport from South Carolina to Maryland",
        "🚐 Olivia booked a minivan transport from Minnesota to Iowa",
        "🚗 Joe just booked a sports car shipment from Oklahoma to Kansas",
        "🚛 Emma just secured a large vehicle transport from Mississippi to Alabama",
        "🚗 Ryan booked a transport from Nebraska to South Dakota",
        "🚚 Megan just scheduled a delivery from Montana to Idaho",
        "🚙 Dave just reserved a 4x4 transport from West Virginia to Virginia",
        "🚐 Ellie booked a camper van transport from Vermont to New Hampshire",
        "🚗 Paul just booked a luxury car shipment from Connecticut to Rhode Island",
        "🚛 Jenny just set up a freight service from Maine to Massachusetts",
        "🚗 Nick just booked a transport from Delaware to Maryland",
        "🚚 Beth just organized a move from Wyoming to Colorado",
        "🚙 Gary just scheduled a SUV transport from North Dakota to Minnesota",
        "🚐 Kate booked a van transport from Alaska to Washington",
        "🚗 Tom just booked a classic car shipment from Hawaii to California",
        "🚛 Alice just secured a freight service from Nevada to Oregon",
        "🚗 Ian booked a transport from Arizona to New Mexico",
        "🚚 April just scheduled a delivery from Utah to Colorado",
        "🚙 Jim just reserved a SUV transport from Louisiana to Texas",
        "🚐 Zoe booked a van transport from New Jersey to Pennsylvania",
        "🚗 Bill just booked a sports car shipment from Kentucky to Ohio",
        "🚛 Grace just secured a large vehicle transport from Wisconsin to Illinois",
        "🚗 Leo booked a transport from Virginia to North Carolina",
        "🚚 Holly just scheduled a delivery from Iowa to Nebraska",
        "🚙 Ben just reserved a 4x4 transport from Kansas to Oklahoma",
        "🚐 Ruby booked a camper van transport from South Dakota to Wyoming",
        "🚗 Jake just booked a luxury car shipment from Maryland to Delaware",
        "🚛 Mia just set up a freight service from New Hampshire to Vermont",
        "🚗 Dave just booked a transport from Massachusetts to Maine",
        "🚚 Tara just organized a move from Idaho to Montana",
        "🚙 Luke just scheduled a SUV transport from Arkansas to Missouri",
        "🚐 Eva booked a van transport from Alabama to Mississippi",
        "🚗 Phil just booked a classic car shipment from Georgia to Tennessee"
      ],
      currentMessage: ''
    };
  },
  mounted() {
    setTimeout(() => {
      console.log('triggered')
      this.showPopup = true;
    }, 5000);
    this.startMessageRotation();
  },
  methods: {
    startMessageRotation() {
      let interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * this.transportMessages.length);
        this.currentMessage = this.transportMessages[randomIndex];
        setTimeout(() => {
          this.currentMessage = '';
        }, 5000);
      }, this.getRandomInterval());
      // Clear the interval when the component is destroyed to prevent memory leaks
      this.$on('hook:beforeDestroy', () => {
        clearInterval(interval);
      });
    },
    getRandomInterval() {
      // Random interval between 5 seconds to 10 seconds
      return Math.floor(Math.random() * (30 * 1000 - 20 * 1000 + 1)) + 10 * 1000;
    }
  }
};
</script>