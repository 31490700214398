<template>
  <section class="hero">
    <div class="hero__overlay"></div>
    <div class="hero__container wrapper">

      <h1 class="hero__title">{{ $t('hero.title') }}</h1>
      <p class="hero__subtitle">{{ $t('hero.subtitle') }}</p>

    </div>
  </section>
</template>

<script>
export default {
  name: "Hero"
}
</script>

<style>

.hero {
  width: 100%;
  height: 250px;
  background-image: url('@/assets/img/truck-banner.png');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 60px 60px;
  position: relative;
}

.hero__overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  background-image: linear-gradient(180deg, #132830 0%, #13283030 100%);
  border-radius: 0px 0px 60px 60px;
}

.hero__container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--ComplementaryColor03);
}

.hero__title {
  text-align: center;
}

.hero__subtitle {
  padding: 10px 0;
  text-align: center;
}

</style>