<template>
  <div class="wrapper">
    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">
      {{ $t("mainContainer.getInstantQuote") }}
    </p>

    <div class="navigation navigation--mod1">
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"
        style="pointer-events: none !important">
        <span><i class="ri-check-fill"></i></span>
        {{ $t("mainContainer.stepOne") }}
      </router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/quote"
        style="pointer-events: none !important">
        <span><i class="ri-check-fill"></i></span>
        {{ $t("mainContainer.stepTwo") }}
      </router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/quote/additional"
        style="pointer-events: none !important">
        <span><i class="ri-check-fill"></i></span>
        {{ $t("mainContainer.stepThree") }}
      </router-link>
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"
        style="pointer-events: none !important">
        <span><i class="ri-check-fill"></i></span>
        {{ $t("mainContainer.stepFour") }}
      </router-link>
    </div>

    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01 mainContainer__mainTitle--mod02">
      {{ $t("mainContainer.bestPriceAndService") }}
    </p>

    <div id="quoteContainer" class="mainContainer">
      <div class="mainContainer__box mainContainer__box--mod04 mainContainer__box--mod03">
        <div class="mainContainer__price" style="padding-top: 15%">
          <div class="mainContainer__priceItem mainContainer__priceItem--mod1">
            <img src="@/assets/img/green-line.svg" alt="Top line" class="mainContainer__priceItemLine" />

            <div class="mainContainer__priority">
              <p class="mainContainer__priorityText1">
                {{ $t("mainContainer.extended") }}
              </p>
              <p class="mainContainer__priorityText2">
                {{ $t("mainContainer.fiveToTenDaysPickUp") }}
              </p>
            </div>

            <p class="mainContainer__totalPrice">
              $ {{ this.$store.state.finalData.oneToTenDaysPrice }}
            </p>

            <div class="mainContainer__booknow">
              <button class="mainContainer__booknowButton" @click="bookNow('extended')">
                {{ $t("mainContainer.bookNowButtonExtended") }}
              </button>

              <ul class="mainContainer__booknowList">
                <li>
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.doorToDoor") }}
                </li>
                <li>
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.makePayment") }}
                </li>
                <li>
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.insured") }}
                </li>
                <li class="mainContainer__booknowListItem">
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.discount") }}
                </li>
              </ul>
            </div>
          </div>

          <div class="mainContainer__priceItem mainContainer__priceItem--mod2">
            <img src="@/assets/img/blue-line.svg" alt="Top line" class="mainContainer__priceItemLine" />

            <div class="mainContainer__priority">
              <p class="mainContainer__priorityText1">
                {{ $t("mainContainer.standard") }}
              </p>
              <p class="mainContainer__priorityText2">
                {{ $t("mainContainer.threeToFiveDaysPickUp") }}
              </p>
            </div>

            <p class="mainContainer__totalPrice">
              $ {{ this.$store.state.finalData.oneToFiveDaysPrice }}
            </p>

            <div class="mainContainer__booknow">
              <button class="mainContainer__booknowButton" @click="bookNow('standard')">
                {{ $t("mainContainer.bookNowButtonStandard") }}
              </button>
              <ul class="mainContainer__booknowList">
                <li>
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.doorToDoor") }}
                </li>
                <li>
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.makePayment") }}
                </li>
                <li>
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.insured") }}
                </li>
                <li class="mainContainer__booknowListItem">
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.discount") }}
                </li>
              </ul>
            </div>
          </div>

          <div class="mainContainer__priceItem mainContainer__priceItem--mod3">
            <img src="@/assets/img/red-line.svg" alt="Top line" class="mainContainer__priceItemLine" />

            <div class="mainContainer__priority">
              <p class="mainContainer__priorityText1">
                {{ $t("mainContainer.urgent") }}
              </p>
              <p class="mainContainer__priorityText2">
                {{ $t("mainContainer.oneToThreeDaysPickUp") }}
              </p>
            </div>

            <p class="mainContainer__totalPrice">
              $ {{ this.$store.state.finalData.oneToThreeDaysPrice }}
            </p>

            <div class="mainContainer__booknow">
              <button class="mainContainer__booknowButton" @click="bookNow('urgent')">
                {{ $t("mainContainer.bookNowButton") }}
              </button>
              <ul class="mainContainer__booknowList">
                <li>
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.doorToDoor") }}
                </li>
                <li>
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.makePayment") }}
                </li>
                <li>
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.insured") }}
                </li>
                <li class="mainContainer__booknowListItem">
                  <img src="@/assets/img/check.png" alt="Check" />
                  {{ $t("mainContainer.discount") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p class="mainContainer__endTitle">{{ $t('mainContainer.sendQuoteToEmail') }}</p>

        <div class="mainContainer__emailFormContainer">
          <form ref="emailForm" class="mainContainer__emailForm" @submit.prevent="sendEmail">
            <input class="emailForm__email" type="email" placeholder="you@example.com">
            <Button id="sendQuoteButton" class="emailForm__submit" type="submit">{{ $t('mainContainer.sendQuoteButton')
              }}</Button>
          </form>
        </div>

        <p class="mainContainer__alertTextLoading" v-if="emailLoading">{{ $t('mainContainer.sending') }}</p>
        <p class="mainContainer__alertText" v-if="emailSend">{{ $t('mainContainer.emailSentSuccessfully') }}</p>
        <p class="mainContainer__alertTextError" v-if="emailError">{{ $t('mainContainer.emailError') }}</p>

        <p class="mainContainer__endSubtitle" style="margin-top: 30px">
          {{ $t("mainContainer.bookWithAgent") }}
        </p>

        <a href="tel:8706001993" target="_blank" class="mainContainer__endPhone">{{ $t("mainContainer.phone") }}</a>
      </div>

      <div class="mainContainer__box mainContainer__box--mod04 mainContainer__box--mod03">
        <div class="mainContainer__informationalBox mainContainer__informationalBox--mod01">
          <p class="infoSection__title infoSection__title--mod01">
            {{ $t("route_information") }}
          </p>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod01">
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title">{{ $t("from") }}</p>
              <p class="infoSection__select" id="selectFrom">
                {{ this.$store.state.routeData.fromAddress || "-" }}
              </p>
            </div>
            <div class="infoSection__container infoSection__container--33 infoSection__container--flex">
              <div class="infoSection__line"></div>
              <div class="infoSection__miles" id="distanceMiles">
                {{ this.$store.state.routeData.distanceFromTo + " MI" }}
              </div>
              <div class="infoSection__line"></div>
            </div>
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title infoSection__textAlignRight">
                {{ $t("to") }}
              </p>
              <p class="infoSection__select infoSection__textAlignRight" id="selectTo">
                {{ this.$store.state.routeData.toAddress || "-" }}
              </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t("pickupdate") }}</p>
              <p class="infoSection__select">
                {{ this.$store.state.routeData.pickupDate || "-" }}
              </p>
            </div>
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t("how_many_veh") }}</p>
              <p class="infoSection__select">
                {{ this.$store.state.routeData.numberOfVehicles || "-" }}
              </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles == 1">
                {{ $t("transported_vehicule") }}
              </p>
              <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles > 1">
                {{ $t("transported_vehicules") }}
              </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles == 1">
                {{ $store.state.vehicleData.vehicleType || "-" }}
              </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">
                1. {{ $store.state.vehicleData.vehicleType || "-" }}
              </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">
                2. {{ $store.state.vehicleData.vehicleType_2 || "-" }}
              </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 2">
                3. {{ $store.state.vehicleData.vehicleType_3 || "-" }}
              </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 3">
                4. {{ $store.state.vehicleData.vehicleType_4 || "-" }}
              </p>
            </div>
          </div>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod02">
            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles == 1">
              {{ $t("aditional_services") }}
            </p>
            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">
              {{ $t("aditional_first_veh") }}
            </p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">
                  {{ $t("runAndDrive") }}
                </p>
                <p class="infoSection__select">
                  {{ $store.state.additionalData.vehicleCondition || "-" }}
                </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">
                  {{ $t("personalObjects") }}
                </p>
                <p class="infoSection__select infoSection__textAlignCenter">
                  {{ $store.state.additionalData.vehiclePounds || "-" }} LB
                </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">
                  {{ $t("truck_Type") }}
                </p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01">
                  {{ $store.state.additionalData.vehicleType || "-" }}
                </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">
              {{ $t("aditional_second_veh") }}
            </p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03"
              v-if="$store.state.routeData.numberOfVehicles > 1">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">
                  {{ $t("runAndDrive") }}
                </p>
                <p class="infoSection__select">
                  {{ $store.state.additionalData.vehicleCondition_2 || "-" }}
                </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">
                  {{ $t("personalObjects") }}
                </p>
                <p class="infoSection__select infoSection__textAlignCenter">
                  {{ $store.state.additionalData.vehiclePounds_2 || "-" }} LB
                </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">
                  {{ $t("truck_Type") }}
                </p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01">
                  {{ $store.state.additionalData.vehicleType_2 || "-" }}
                </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 2">
              {{ $t("aditional_third_veh") }}
            </p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03"
              v-if="$store.state.routeData.numberOfVehicles > 2">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">
                  {{ $t("runAndDrive") }}
                </p>
                <p class="infoSection__select">
                  {{ $store.state.additionalData.vehicleCondition_3 || "-" }}
                </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">
                  {{ $t("personalObjects") }}
                </p>
                <p class="infoSection__select infoSection__textAlignCenter">
                  {{ $store.state.additionalData.vehiclePounds_3 || "-" }} LB
                </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">
                  {{ $t("truck_Type") }}
                </p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01">
                  {{ $store.state.additionalData.vehicleType_3 || "-" }}
                </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 3">
              {{ $t("aditional_fourth_veh") }}
            </p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03"
              v-if="$store.state.routeData.numberOfVehicles > 3">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">
                  {{ $t("runAndDrive") }}
                </p>
                <p class="infoSection__select">
                  {{ $store.state.additionalData.vehicleCondition_4 || "-" }}
                </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">
                  {{ $t("personalObjects") }}
                </p>
                <p class="infoSection__select infoSection__textAlignCenter">
                  {{ $store.state.additionalData.vehiclePounds_4 || "-" }} LB
                </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">
                  {{ $t("truck_Type") }}
                </p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01">
                  {{ $store.state.additionalData.vehicleType_4 || "-" }}
                </p>
              </div>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title infoSection__title--mod4">
                <span>$ {{ this.$store.state.finalData.totalPriceWithoutDiscount }}
                </span>
                $50 discount
              </p>
              <p class="infoSection__select infoSection__select--mod1">
                $ {{ this.$store.state.finalData.totalPrice }}
              </p>
            </div>
          </div>

          <!-- <div class="formContainer__submitBox formContainer__submitBox--custom02">
            <button class="formContainer__submit formContainer__submit--custom01" v-on:click="backAction">Back</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Finish",
  data() {
    return {
      emailSend: false,
      emailError: false,
      emailLoading: false,
    };
  },
  methods: {
    async sendEmail(event) {
      const vehicleData = () => {
        let data = [
          {
            vehicle: this.$store.state.vehicleData.vehicleType,
            vehicleCondition: this.$store.state.additionalData.vehicleCondition,
            vehiclePounds: this.$store.state.additionalData.vehiclePounds,
            vehicleType: this.$store.state.additionalData.vehicleType
          },
          {
            vehicle: this.$store.state.vehicleData.vehicleType_2,
            vehicleCondition: this.$store.state.additionalData.vehicleCondition_2,
            vehiclePounds: this.$store.state.additionalData.vehiclePounds_2,
            vehicleType: this.$store.state.additionalData.vehicleType_2
          },
          {
            vehicle: this.$store.state.vehicleData.vehicleType_3,
            vehicleCondition: this.$store.state.additionalData.vehicleCondition_3,
            vehiclePounds: this.$store.state.additionalData.vehiclePounds_3,
            vehicleType: this.$store.state.additionalData.vehicleType_3
          },
          {
            vehicle: this.$store.state.vehicleData.vehicleType_4,
            vehicleCondition: this.$store.state.additionalData.vehicleCondition_4,
            vehiclePounds: this.$store.state.additionalData.vehiclePounds_4,
            vehicleType: this.$store.state.additionalData.vehicleType_4
          }
        ];
        let numberVehicles = this.$store.state.routeData.numberOfVehicles;
        if (!numberVehicles > 1) {
          return [];
        }
        let response = [];
        for (let i = 0; i < numberVehicles; i++) {
          response.push(data[i]);
        }
        return response;
      }
      const email_body = {
        lang: this.$i18n.locale,
        to: event.target[0].value,
        routeData: {
          fromAddress: this.$store.state.routeData.fromAddress,
          toAddress: this.$store.state.routeData.toAddress,
          pickupDate: this.$store.state.routeData.pickupDate,
          distanceFromTo: this.$store.state.routeData.distanceFromTo,
        },
        vehicleData: vehicleData(),
        totalPrice: this.$store.state.finalData.totalPrice,
        totalPriceWithoutDiscount: this.$store.state.finalData.totalPriceWithoutDiscount,
      }

      document.querySelector("#sendQuoteButton").setAttribute("disabled", "");

      if (event.target[0].value.length >= 1) {
        const self = this;
        self.emailSend = false;
        self.emailLoading = true;
        console.log(JSON.stringify(this.$store));

        axios.post("https://ratesautotransport.com/api/email/quote",
          email_body
        ).then((message) => {
          document
            .querySelector("#sendQuoteButton")
            .removeAttribute("disabled");
          event.target.reset();
          self.emailLoading = false;
          self.emailSend = true;
        });
      }
    },
    bookNow(string) {
      document
        .querySelectorAll(".mainContainer__booknowButton")
        .forEach((item) => {
          item.setAttribute("disabled", "");
        });

      this.$store.dispatch("SAVE_RESERVATION_TYPE_ACTION", string);

      axios
        .post("https://ratesautotransport.com/api/update_booking", {
          document_id: this.$store.state.documentId,
          data: {
            ...this.$store.state,
          },
        })
        .then((response) => {
          this.$router.push(`/checkout/${this.$store.state.documentId}`);
        })
        .catch((er) => {
          console.log(er);
        });
    },
    backAction() {
      this.$router.push({ name: "Additional" });
    },
  },
};
</script>