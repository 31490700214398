<template>
  <div>

    <Hero />

    <section class="quote">
      <div class="quote__container wrapper">
        <!-- <h2 class="quote__title">{{$t('check_3_quotes')}}</h2> -->
        <router-view/>
      </div>
    </section>

    <Content />

  </div>
</template>

<script>
import axios from 'axios';
import Hero from '@/components/Hero.vue';
import Content from '@/components/Content.vue';

export default {
  name: "Checkout",
  components: {
    Hero,
    Content
  },
  mounted() {

    axios.post('https://ratesautotransport.com/api/get_booking', { document_id: this.$route.params.id })
    .then(response => {

      this.$store.dispatch('CHECKOUT_SAVE_DATA_TO_STORE_ACTION', response.data);
      this.$store.dispatch('SAVE_DOCUMENT_ID_ACTION', this.$route.params.id);

    })
    .catch(er => {

      console.log(er);

    });

  }
}
</script>