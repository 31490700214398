<template>
  <div class="wrapper">

    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">{{ $t('mainContainer.mainTitle') }}</p>

    <div class="mainContainer">

      <div class="mainContainer__box mainContainer__box--mod01">

        <div class="navigation">
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"><span><i class="ri-check-fill"></i></span> {{ $t('mainContainer.stepOne') }}</router-link>
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/quote"><span><i class="ri-check-fill"></i></span> {{ $t('mainContainer.stepTwo') }}</router-link>
          <router-link class="navigation__link navigation__link--active navigation__link--done" to="/"><span><i class="ri-map-pin-line"></i></span> {{ $t('mainContainer.stepThree') }}</router-link>
          <router-link class="navigation__link" to="/"> {{ $t('mainContainer.stepFour') }}</router-link>
        </div>

        <div class="formContainer">

          <p class="formContainer__infoText" v-if="$store.state.routeData.numberOfVehicles == 1">{{ $t('select_aditional_services')}}</p>
          <p class="formContainer__infoText" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('select_aditional_services_for')}} <b>{{ $t('vehicle.firstVehicle') }}</b></p>

          <div class="formContainer__additionalServices" id="vehicle_number_1">

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{$t('runAndDrive')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown1">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehicleCondition }}</p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="operable" name="vehicle_condition" value="Operable" v-model="vehicleCondition">
                    <label for="operable" v-on:click="dropdown1">Operable</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="inoperable" name="vehicle_condition" value="Inoperable" v-model="vehicleCondition">
                    <label for="inoperable" v-on:click="dropdown1">Inoperable</label>
                  </div>
                </div>

              </div>
            </div>

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{ $t('personalObjects')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown2">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehiclePounds }} LB </p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="100lb" name="vehicle_pounds" value="100" v-model="vehiclePounds">
                    <label for="100lb" v-on:click="dropdown2">100 LB (Included)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="200lb" name="vehicle_pounds" value="200" v-model="vehiclePounds">
                    <label for="200lb" v-on:click="dropdown2">200 LB (100usd)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="300lb" name="vehicle_pounds" value="300" v-model="vehiclePounds">
                    <label for="300lb" v-on:click="dropdown2">300 LB (200usd)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="400lb" name="vehicle_pounds" value="400" v-model="vehiclePounds">
                    <label for="400lb" v-on:click="dropdown2">400 LB (300usd)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="500lb" name="vehicle_pounds" value="500" v-model="vehiclePounds">
                    <label for="500lb" v-on:click="dropdown2">500 LB (400usd)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="600lb" name="vehicle_pounds" value="600" v-model="vehiclePounds">
                    <label for="600lb" v-on:click="dropdown2">600 LB (500usd)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="700lb" name="vehicle_pounds" value="700" v-model="vehiclePounds">
                    <label for="700lb" v-on:click="dropdown2">700 LB (600usd)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="800lb" name="vehicle_pounds" value="800" v-model="vehiclePounds">
                    <label for="800lb" v-on:click="dropdown2">800 LB (700usd)</label>
                  </div>
                </div>

              </div>
            </div>

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{$t('truck_Type')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown3">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehicleType }} </p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="open" name="vehicle_type" value="Open" v-model="vehicleType">
                    <label for="open" v-on:click="dropdown3">{{$t('open_truck')}}</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="enclosed" name="vehicle_type" value="Enclosed" v-model="vehicleType">
                    <label for="enclosed" v-on:click="dropdown3">{{$t('enclosed_truck')}}</label>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <p class="formContainer__infoText formContainer__infoText--2" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('select_aditional_services_for')}} <b>{{ $t('vehicle.secondVehicle') }}</b></p>

          <div class="formContainer__additionalServices" id="vehicle_number_2" v-if="$store.state.routeData.numberOfVehicles > 1">

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{$t('runAndDrive')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown4">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehicleCondition_2 }}</p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="operable_2" name="vehicle_condition_2" value="Operable" v-model="vehicleCondition_2">
                    <label for="operable_2" v-on:click="dropdown4">Operable</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="inoperable_2" name="vehicle_condition_2" value="Inoperable" v-model="vehicleCondition_2">
                    <label for="inoperable_2" v-on:click="dropdown4">Inoperable</label>
                  </div>
                </div>

              </div>
            </div>

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{ $t('personalObjects')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown5">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehiclePounds_2 }} LB </p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="100lb2" name="vehicle_pounds_2" value="100" v-model="vehiclePounds_2">
                    <label for="100lb2" v-on:click="dropdown5">100 LB (Included)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="200lb2" name="vehicle_pounds_2" value="200" v-model="vehiclePounds_2">
                    <label for="200lb2" v-on:click="dropdown5">200 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="300lb2" name="vehicle_pounds_2" value="300" v-model="vehiclePounds_2">
                    <label for="300lb2" v-on:click="dropdown5">300 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="400lb2" name="vehicle_pounds_2" value="400" v-model="vehiclePounds_2">
                    <label for="400lb2" v-on:click="dropdown5">400 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="500lb2" name="vehicle_pounds_2" value="500" v-model="vehiclePounds_2">
                    <label for="500lb2" v-on:click="dropdown5">500 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="600lb2" name="vehicle_pounds_2" value="600" v-model="vehiclePounds_2">
                    <label for="600lb2" v-on:click="dropdown5">600 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="700lb2" name="vehicle_pounds_2" value="700" v-model="vehiclePounds_2">
                    <label for="700lb2" v-on:click="dropdown5">700 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="800lb2" name="vehicle_pounds_2" value="800" v-model="vehiclePounds_2">
                    <label for="800lb2" v-on:click="dropdown5">800 LB</label>
                  </div>
                </div>

              </div>
            </div>

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{$t('truck_Type')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown6">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehicleType_2 }} </p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="open2" name="vehicle_type_2" value="Open" v-model="vehicleType_2">
                    <label for="open2" v-on:click="dropdown6">{{$t('open_truck')}}</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="enclosed2" name="vehicle_type_2" value="Enclosed" v-model="vehicleType_2">
                    <label for="enclosed2" v-on:click="dropdown6">{{$t('enclosed_truck')}}</label>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <p class="formContainer__infoText formContainer__infoText--2" v-if="$store.state.routeData.numberOfVehicles > 2">{{ $t('select_aditional_services_for')}} <b>{{ $t('vehicle.thirdVehicle') }}</b></p>

          <div class="formContainer__additionalServices" id="vehicle_number_3" v-if="$store.state.routeData.numberOfVehicles > 2">

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{$t('runAndDrive')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown7">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehicleCondition_3 }}</p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="operable_3" name="vehicle_condition_3" value="Operable" v-model="vehicleCondition_3">
                    <label for="operable_3" v-on:click="dropdown7">Operable</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="inoperable_3" name="vehicle_condition_3" value="Inoperable" v-model="vehicleCondition_3">
                    <label for="inoperable_3" v-on:click="dropdown7">Inoperable</label>
                  </div>
                </div>

              </div>
            </div>

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{ $t('personalObjects')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown8">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehiclePounds_3 }} LB </p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="100lb3" name="vehicle_pounds_3" value="100" v-model="vehiclePounds_3">
                    <label for="100lb3" v-on:click="dropdown8">100 LB (Included)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="200lb3" name="vehicle_pounds_3" value="200" v-model="vehiclePounds_3">
                    <label for="200lb3" v-on:click="dropdown8">200 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="300lb3" name="vehicle_pounds_3" value="300" v-model="vehiclePounds_3">
                    <label for="300lb3" v-on:click="dropdown8">300 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="400lb3" name="vehicle_pounds_3" value="400" v-model="vehiclePounds_3">
                    <label for="400lb3" v-on:click="dropdown8">400 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="500lb3" name="vehicle_pounds_3" value="500" v-model="vehiclePounds_3">
                    <label for="500lb3" v-on:click="dropdown8">500 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="600lb3" name="vehicle_pounds_3" value="600" v-model="vehiclePounds_3">
                    <label for="600lb3" v-on:click="dropdown8">600 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="700lb3" name="vehicle_pounds_3" value="700" v-model="vehiclePounds_3">
                    <label for="700lb3" v-on:click="dropdown8">700 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="800lb3" name="vehicle_pounds_3" value="800" v-model="vehiclePounds_3">
                    <label for="800lb3" v-on:click="dropdown8">800 LB</label>
                  </div>
                </div>

              </div>
            </div>

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{$t('truck_Type')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown9">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehicleType_3 }} </p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="open3" name="vehicle_type_3" value="Open" v-model="vehicleType_3">
                    <label for="open3" v-on:click="dropdown9">{{$t('open_truck')}}</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="enclosed3" name="vehicle_type_3" value="Enclosed" v-model="vehicleType_3">
                    <label for="enclosed3" v-on:click="dropdown9">{{$t('enclosed_truck')}}</label>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <p class="formContainer__infoText formContainer__infoText--2" v-if="$store.state.routeData.numberOfVehicles > 3">{{ $t('select_aditional_services_for')}} <b>{{ $t('vehicle.fourthVehicle') }}</b></p>

          <div class="formContainer__additionalServices" id="vehicle_number_4" v-if="$store.state.routeData.numberOfVehicles > 3">

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{$t('runAndDrive')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown10">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehicleCondition_4 }}</p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="operable_4" name="vehicle_condition_4" value="Operable" v-model="vehicleCondition_4">
                    <label for="operable_4" v-on:click="dropdown10">Operable</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="inoperable_4" name="vehicle_condition_4" value="Inoperable" v-model="vehicleCondition_4">
                    <label for="inoperable_4" v-on:click="dropdown10">Inoperable</label>
                  </div>
                </div>

              </div>
            </div>

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{ $t('personalObjects')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown11">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehiclePounds_4 }} LB </p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="100lb4" name="vehicle_pounds_4" value="100" v-model="vehiclePounds_4">
                    <label for="100lb4" v-on:click="dropdown11">100 LB (Included)</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="200lb4" name="vehicle_pounds_4" value="200" v-model="vehiclePounds_4">
                    <label for="200lb4" v-on:click="dropdown11">200 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="300lb4" name="vehicle_pounds_4" value="300" v-model="vehiclePounds_4">
                    <label for="300lb4" v-on:click="dropdown11">300 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="400lb4" name="vehicle_pounds_4" value="400" v-model="vehiclePounds_4">
                    <label for="400lb4" v-on:click="dropdown11">400 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="500lb4" name="vehicle_pounds_4" value="500" v-model="vehiclePounds_4">
                    <label for="500lb4" v-on:click="dropdown11">500 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="600lb4" name="vehicle_pounds_4" value="600" v-model="vehiclePounds_4">
                    <label for="600lb4" v-on:click="dropdown11">600 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="700lb4" name="vehicle_pounds_4" value="700" v-model="vehiclePounds_4">
                    <label for="700lb4" v-on:click="dropdown11">700 LB</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="800lb4" name="vehicle_pounds_4" value="800" v-model="vehiclePounds_4">
                    <label for="800lb4" v-on:click="dropdown11">800 LB</label>
                  </div>
                </div>

              </div>
            </div>

            <div class="additionalServices__container">
              <p class="additionalServices__title additionalServices__title--custom01">{{$t('truck_Type')}}</p>
              <div class="additionalServices__box" v-on:click="dropdown12">

                <div class="additionalServices__header">
                  <p class="additionalServices__title"> {{ vehicleType_4 }} </p>
                  <span class="additionalServices__icon"><i class="additionalServices__i ri-arrow-down-s-line"></i></span>
                </div>

                <div class="additionalServices__dropdown isHidden">
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="open4" name="vehicle_type_4" value="Open" v-model="vehicleType_4">
                    <label for="open4" v-on:click="dropdown12">{{$t('open_truck')}}</label>
                  </div>
                  <div class="additionalServices__dropdown--box">
                    <input type="radio" id="enclosed4" name="vehicle_type_4" value="Enclosed" v-model="vehicleType_4">
                    <label for="enclosed4" v-on:click="dropdown12">{{$t('enclosed_truck')}}</label>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div class="formContainer__submitBox">
            <button class="formContainer__submit" v-on:click="backAction">{{ $t('back') }}</button>
            <button class="formContainer__submit" id="continueStep" v-on:click="nextStep">{{ $t('next') }}</button>
          </div>

        </div>

      </div>

      <div class="mainContainer__box mainContainer__box--mod02">

        <div class="mainContainer__informationalBox">

          <p class="infoSection__title infoSection__title--mod01">{{ $t('route_information') }}</p>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod01">
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title">{{ $t('from') }}</p>
              <p class="infoSection__select" id="selectFrom"> {{ this.$store.state.routeData.fromAddress || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33 infoSection__container--flex">
              <div class="infoSection__line"></div>
              <div class="infoSection__miles" id="distanceMiles"> {{ this.$store.state.routeData.distanceFromTo + ' MI' }} </div>
              <div class="infoSection__line"></div>
            </div>
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title infoSection__textAlignRight">{{ $t('to') }}</p>
              <p class="infoSection__select infoSection__textAlignRight" id="selectTo"> {{ this.$store.state.routeData.toAddress || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('pickupdate')}}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.pickupDate || '-' }} </p>
            </div>
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('how_many_veh')}}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.numberOfVehicles || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
<p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles == 1">{{ $t('transported_vehicule') }}</p>
          <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('transported_vehicules') }}</p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles == 1"> {{ $store.state.vehicleData.vehicleType || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">1. {{ $store.state.vehicleData.vehicleType || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">2. {{ $store.state.vehicleData.vehicleType_2 || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 2">3. {{ $store.state.vehicleData.vehicleType_3 || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 3">4. {{ $store.state.vehicleData.vehicleType_4 || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod02">

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles == 1">{{ $t('aditional_services')}}</p>
            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('aditional_first_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ vehicleCondition || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ vehiclePounds || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{$t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ vehicleType || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('aditional_second_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03" v-if="$store.state.routeData.numberOfVehicles > 1">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ vehicleCondition_2 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ vehiclePounds_2 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{$t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ vehicleType_2 || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 2">{{ $t('aditional_third_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03" v-if="$store.state.routeData.numberOfVehicles > 2">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ vehicleCondition_3 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ vehiclePounds_3 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{$t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ vehicleType_3 || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 3">{{ $t('aditional_fourth_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03" v-if="$store.state.routeData.numberOfVehicles > 3">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ vehicleCondition_4 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ vehiclePounds_4 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{$t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ vehicleType_4 || '-' }} </p>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "Additional",
  data() {
    return {
      vehicleCondition: 'Operable',
      vehicleCondition_2: 'Operable',
      vehicleCondition_3: 'Operable',
      vehicleCondition_4: 'Operable',
      vehicleConditionCheck: true,
      vehiclePounds: '100',
      vehiclePounds_2: '100',
      vehiclePounds_3: '100',
      vehiclePounds_4: '100',
      vehiclePoundsCheck: true,
      vehicleType: 'Open',
      vehicleType_2: 'Open',
      vehicleType_3: 'Open',
      vehicleType_4: 'Open',
      vehicleTypeCheck: true,
      continueButtonCheck: true
    }
  },
  methods: {
    checkIfCanContinue() {
      if (this.vehicleConditionCheck === true && this.vehiclePoundsCheck === true && this.vehicleTypeCheck === true) {
        this.continueButtonCheck = true;
      }
    },
    dropdown1() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[0].classList.toggle("isHidden");

      if (dropDownIcon[0].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[0].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[0].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[0].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[0].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown2() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[1].classList.toggle("isHidden");

      if (dropDownIcon[1].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[1].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[1].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[1].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[1].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown3() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[2].classList.toggle("isHidden");

      if (dropDownIcon[2].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[2].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[2].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[2].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[2].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown4() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[3].classList.toggle("isHidden");

      if (dropDownIcon[3].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[3].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[3].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[3].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[3].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown5() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[4].classList.toggle("isHidden");

      if (dropDownIcon[4].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[4].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[4].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[4].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[4].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown6() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[5].classList.toggle("isHidden");

      if (dropDownIcon[5].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[5].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[5].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[5].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[5].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown7() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[6].classList.toggle("isHidden");

      if (dropDownIcon[6].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[6].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[6].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[6].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[6].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown8() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[7].classList.toggle("isHidden");

      if (dropDownIcon[7].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[7].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[7].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[7].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[7].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown9() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[8].classList.toggle("isHidden");

      if (dropDownIcon[8].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[8].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[8].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[8].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[8].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown10() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[9].classList.toggle("isHidden");

      if (dropDownIcon[9].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[9].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[9].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[9].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[9].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown11() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[10].classList.toggle("isHidden");

      if (dropDownIcon[10].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[10].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[10].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[10].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[10].classList.add('ri-arrow-down-s-line');
      }

    },
    dropdown12() {

      const dropDown = document.querySelectorAll('.additionalServices__dropdown');
      const dropDownIcon = document.querySelectorAll('.additionalServices__i');

      dropDown[11].classList.toggle("isHidden");

      if (dropDownIcon[11].classList.contains('ri-arrow-down-s-line')) {
        dropDownIcon[11].classList.remove('ri-arrow-down-s-line');
        dropDownIcon[11].classList.add('ri-arrow-up-s-line');
      } else {
        dropDownIcon[11].classList.remove('ri-arrow-up-s-line');
        dropDownIcon[11].classList.add('ri-arrow-down-s-line');
      }

    },
    nextStep() {

      const additionalData = {
        vehicleCondition: this.vehicleCondition,
        vehicleCondition_2: this.vehicleCondition_2,
        vehicleCondition_3: this.vehicleCondition_3,
        vehicleCondition_4: this.vehicleCondition_4,
        vehiclePounds: this.vehiclePounds,
        vehiclePounds_2: this.vehiclePounds_2,
        vehiclePounds_3: this.vehiclePounds_3,
        vehiclePounds_4: this.vehiclePounds_4,
        vehicleType: this.vehicleType,
        vehicleType_2: this.vehicleType_2,
        vehicleType_3: this.vehicleType_3,
        vehicleType_4: this.vehicleType_4
      }

      this.$store.dispatch('SAVE_ADDITIONAL_STEP_ACTION', additionalData);

      document.querySelector('#continueStep').setAttribute("disabled","");

      axios.post('https://ratesautotransport.com/api/create_booking', this.$store.state)
      .then(response => {

        this.$store.dispatch('SAVE_DOCUMENT_ID_ACTION', response.data.document_id);
        this.$router.replace({ name: "Finish" });

      })
      .catch(er => {

        console.log(er);

      });

    },
    backAction() {
      this.$router.push({ name: "Vehicle" });
    }
  },
  watch: {
    vehicleCondition(newValue, oldValue) {
      if (newValue.length > 1) {
        this.vehicleConditionCheck = true;
        this.checkIfCanContinue();
      }
    },
    vehiclePounds(newValue, oldValue) {
      if (newValue.length > 1) {
        this.vehiclePoundsCheck = true;
        this.checkIfCanContinue();
      }
    },
    vehicleType(newValue, oldValue) {
      if (newValue.length > 1) {
        this.vehicleTypeCheck = true;
        this.checkIfCanContinue();
      }
    },
    continueButtonCheck(newValue, oldValue) {
      if (newValue === true) {
        document.querySelector('#continueStep').removeAttribute("disabled");
      }
    }
  }
}
</script>