<template>
  <div class="wrapper">

    <p class="mainContainer__mainTitle mainContainer__mainTitle--mod01">{{ $t('completeTheFinalSteps') }}</p>

    <div class="navigation navigation--mod1">
      <router-link class="navigation__link navigation__link--active navigation__link--done" to="/" style="pointer-events: none !important;"><span><strong>1</strong></span>{{ $t('transport') }}</router-link>
      <router-link class="navigation__link" to="/"><span><strong style="color:#FFFFFF;">2</strong></span>{{ $t('pickup') }}</router-link>
      <router-link class="navigation__link" to="/"><span><strong style="color:#FFFFFF;">3</strong></span>{{ $t('delivery') }}</router-link>
      <router-link class="navigation__link" to="/"><span><strong style="color:#ffffff;">4</strong></span>{{ $t('finish') }}</router-link>
    </div>

    <div class="mainContainer" style="margin-top:30px;">

      <div class="mainContainer__box mainContainer__box--mod04 mainContainer__box--mod03" style="padding:30px;">

        <p class="mainContainer__mainTitle mainContainer__mainTitle--mod02">{{$t('transport')}}</p>
        <p style="margin-left:10px;margin-top:-10px;">{{$t('more_details')}}</p>

        <form class="formContainer__box formContainer__box--custom01" @submit.prevent="sendFormData" style="margin-top:10px;">

          <div class="formContainer__inputBox formContainer__inputBox--custom01">
            <label for="firstName">{{$t('first_name')}}</label>
            <input type="text" name="firstName" id="firstName" placeholder="John" v-model="firstName" autocomplete="off" required>
          </div>
          <div class="formContainer__inputBox formContainer__inputBox--custom01">
            <label for="lastName">{{$t('last_name')}}</label>
            <input type="text" name="lastName" id="lastName" placeholder="Doe" v-model="lastName" autocomplete="off" required>
          </div>
          <div class="formContainer__three">
            <div class="formContainer__inputBox formContainer__inputBox--custom01">
              <label for="email">E-mail</label>
              <input type="email" name="email" id="email" placeholder="Enter your e-mail address" v-model="email" autocomplete="off" required>
            </div>
            <div class="formContainer__inputBox formContainer__inputBox--custom03 formContainer__inputBox--custom01">
              <label for="phoneNumber">{{$t('phone_number')}}</label>
              <input type="text" name="phoneNumber" id="phoneNumber" placeholder="0 000 000 0000 000000000" v-model="phoneNumber" autocomplete="off" maxlength="15" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" required>
            </div>
          </div>

          <input type="submit" class="formContainer__submit formContainer__submit--custom01" :value="$t('next')" style="margin-top:20px;">

        </form>

      </div>

      <div class="mainContainer__box mainContainer__box--mod04 mainContainer__box--mod03">

        <div class="mainContainer__informationalBox mainContainer__informationalBox--mod01">

          <p class="infoSection__title infoSection__title--mod01">{{ $t('route_information') }}</p>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod01">
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title">{{ $t('from') }}</p>
              <p class="infoSection__select" id="selectFrom"> {{ this.$store.state.routeData.fromAddress || '-' }} </p>
            </div>
            <div class="infoSection__container infoSection__container--33 infoSection__container--flex">
              <div class="infoSection__line"></div>
              <div class="infoSection__miles" id="distanceMiles"> {{ this.$store.state.routeData.distanceFromTo + ' MI' }} </div>
              <div class="infoSection__line"></div>
            </div>
            <div class="infoSection__container infoSection__container--33">
              <p class="infoSection__title infoSection__textAlignRight">{{ $t('to') }}</p>
              <p class="infoSection__select infoSection__textAlignRight" id="selectTo"> {{ this.$store.state.routeData.toAddress || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('pickupdate')}}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.pickupDate || '-' }} </p>
            </div>
            <div class="infoSection__container">
              <p class="infoSection__title">{{ $t('how_many_veh')}}</p>
              <p class="infoSection__select"> {{ this.$store.state.routeData.numberOfVehicles || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
<p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles == 1">{{ $t('transported_vehicule') }}</p>
          <p class="infoSection__title" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('transported_vehicules') }}</p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles == 1"> {{ $store.state.vehicleData.vehicleType || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">1. {{ $store.state.vehicleData.vehicleType || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 1">2. {{ $store.state.vehicleData.vehicleType_2 || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 2">3. {{ $store.state.vehicleData.vehicleType_3 || '-' }} </p>
              <p class="infoSection__select" v-if="$store.state.routeData.numberOfVehicles > 3">4. {{ $store.state.vehicleData.vehicleType_4 || '-' }} </p>
            </div>
          </div>

          <div class="informationalBox__infoSection informationalBox__infoSection--mod02">

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles == 1">{{ $t('aditional_services')}}</p>
            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('aditional_first_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ $store.state.additionalData.vehiclePounds || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{ $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ $store.state.additionalData.vehicleType || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 1">{{ $t('aditional_second_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03" v-if="$store.state.routeData.numberOfVehicles > 1">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition_2 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ $store.state.additionalData.vehiclePounds_2 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{ $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ $store.state.additionalData.vehicleType_2 || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 2">{{ $t('aditional_third_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03" v-if="$store.state.routeData.numberOfVehicles > 2">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition_3 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ $store.state.additionalData.vehiclePounds_3 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{ $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ $store.state.additionalData.vehicleType_3 || '-' }} </p>
              </div>
            </div>

            <p class="infoSection__title infoSection__title--mod02" v-if="$store.state.routeData.numberOfVehicles > 3">{{ $t('aditional_fourth_veh')}}</p>

            <div class="informationalBox__infoSection informationalBox__infoSection--mod03" v-if="$store.state.routeData.numberOfVehicles > 3">
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03">{{ $t('runAndDrive')}}</p>
                <p class="infoSection__select"> {{ $store.state.additionalData.vehicleCondition_4 || '-' }} </p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__title--mod03 infoSection__textAlignCenter">{{ $t('personalObjects')}}</p>
                <p class="infoSection__select infoSection__textAlignCenter"> {{ $store.state.additionalData.vehiclePounds_4 || '-' }} LB</p>
              </div>
              <div class="infoSection__container infoSection__container--33">
                <p class="infoSection__title infoSection__textAlignRight infoSection__title--mod03">{{ $t('truck_Type')}}</p>
                <p class="infoSection__select infoSection__textAlignRight infoSection__textAlignRight--mod01"> {{ $store.state.additionalData.vehicleType_4 || '-' }} </p>
              </div>
            </div>

          </div>

          <div class="informationalBox__infoSection">
            <div class="infoSection__container">
              <p class="infoSection__title infoSection__title--mod4" v-if='$store.state.reservationType != ""'>{{ $t('discount_price') }}<br><span style="text-decoration:none;font-size:2.5rem;">$ {{ $store.state.reservationType === 'extended' ? $store.state.finalData.oneToTenDaysPrice : $store.state.reservationType === 'standard' ? $store.state.finalData.oneToFiveDaysPrice : $store.state.reservationType === 'urgent' ? $store.state.finalData.oneToThreeDaysPrice : ''}} </span></p>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Transport',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    }
  },
  methods: {
    async sendFormData() {
      // Save data to store
      let price;

      if (this.$store.state.reservationType === 'extended') {
        price = this.$store.state.finalData.oneToTenDaysPrice;
      } else if (this.$store.state.reservationType === 'standard') {
        price = this.$store.state.finalData.oneToFiveDaysPrice;
      } else if (this.$store.state.reservationType === 'urgent') {
        price = this.$store.state.finalData.oneToThreeDaysPrice;
      }

      this.$store.dispatch('SAVE_CHECKOUT_TRANSPORT_ACTION', {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phoneNumber: this.phoneNumber
      });

      // Get booking data from Firebase
      const bookingData = await this.getBookingData(this.$route.params.id);
      // Prepare customerInfo by merging bookingData and new data
      const customerInfo = {
        name: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phoneNumber,
        zipCodePickup: bookingData.routeData.fromAddress,
        zipCodeDelivery: bookingData.routeData.toAddress,
        reservationType: bookingData.reservationType,
        vehicleData: bookingData.vehicleData,
        price: parseFloat(price.replace(/,/g, '')),
      };

      this.$store.dispatch('SAVE_CUSTOMER_INFO_ACTION', customerInfo);
      // Navigate to the next page
      this.$router.push(`/checkout/${this.$route.params.id}/pickup`);
    },


    async getBookingData(document_id) {
      try {
        const response = await axios.post('https://ratesautotransport.com/api/get_booking', { document_id });
        return response.data;
      } catch (error) {
        console.error("Error getting booking data:", error);
        return null;
      }
    },
  },
}
</script>

<style>

</style>