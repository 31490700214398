import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBUliZ7msrYFQ_w3rwO5idJe_xD0B-fRsA",
  authDomain: "tuyoyo-94184.firebaseapp.com",
  projectId: "tuyoyo-94184",
  storageBucket: "tuyoyo-94184.appspot.com",
  messagingSenderId: "774848836952",
  appId: "1:774848836952:web:0c025f169b4f8078bba12d"
};

initializeApp(firebaseConfig);